import React from 'react'
import { Route, NavLink, Routes } from 'react-router-dom'
import Home from "../pages/Home"
import About from "../pages/About"
import Run from "../pages/Run"
import Edit from "../pages/Edit"
import Results from "../pages/Results"

export const NavBar = () => (
    <>
        <NavLink className='sjs-nav-button' to="/"><span>Опросы</span></NavLink>
        {/* <NavLink className='sjs-nav-button' to="/about"><span>About</span></NavLink> */}
    </>
)

const NoMatch = () => (<><h1>404</h1></>)

const Content = (): React.ReactElement => (
    <>
        <Routes>
            <Route path="/" element={<About/>}></Route>
            <Route path="/rusforms" element={<Home/>}></Route>
            <Route path="/rusforms/run/:id" element={<Run/>}></Route>
            <Route path="/rusforms/edit/:id" element={<Edit/>}></Route>
            <Route path="/rusforms/results/:id" element={<Results/>}></Route>
            <Route element={<NoMatch/>}></Route>
        </Routes>
    </>
)

export default Content