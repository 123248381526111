import React, { useEffect } from 'react'
import { create, load, remove, removeReducer} from '../redux/surveys'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { Link } from 'react-router-dom'
import './Surveys.css'


const Surveys = (): React.ReactElement => {
    const surveys = useReduxSelector(state => state.surveys.surveys)
    const dispatch = useReduxDispatch()

    const postStatus = useReduxSelector(state => state.surveys.statusLoad)
    const delStatus = useReduxSelector(state => state.surveys.statusDelete)

    useEffect(() => {
      if (postStatus == 'idle') {
        dispatch(load())
    }
      if (delStatus == 'succeeded') {
        dispatch(load())
    }

    }, [dispatch, surveys])    

    const handleRemoveSurvey = (id: string) => {
        // вызов функции для передачи id объекта для удаления в БД 
        dispatch(remove(id))
        // вызов функции для удаления объекта из хранилища Redux
        dispatch(removeReducer(id))
    };
    
    return (<>
        <table className='sjs-surveys-list'>
            {surveys.map(survey => 
                <tr key={survey.id} className='sjs-surveys-list__row'>
                    <td><span>{survey.name}</span></td>
                    <td>
                        <Link className='sjs-button' to={'run/' + survey.id}><span>Запустить</span></Link>
                        <Link className='sjs-button' to={'edit/' + survey.id}><span>Редактировать</span></Link>
                        <Link className='sjs-button' to={'results/' + survey.id}><span>Результаты</span></Link>
                        <span className='sjs-button sjs-remove-btn' onClick={() => handleRemoveSurvey(survey.id)}>Удалить</span>
                    </td>
                </tr>
            )}
        </table>
        <div className='sjs-surveys-list__footer'>
            <span className='sjs-button sjs-add-btn' title='increment' onClick={() => dispatch(create())}>Добавить опрос</span>                        
        </div>
    </>)
}

export default Surveys;
