import { useParams } from 'react-router'
import { Model, StylesManager } from '../apps/survey-core'
import '../apps/survey-core/defaultV2.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Survey } from '../apps/survey-react-ui'
import { post, download } from '../redux/results'
import { useReduxDispatch } from '../redux'
import { apiBaseAddress } from '../models/survey'
import React from 'react'

StylesManager.applyTheme("defaultV2")

const Run = () => {
    const dispatch = useReduxDispatch()
    const { id } = useParams();
    const [survey, setSurvey] = useState({
        id: 0,
        name: '',
        json: {},
        is_active: true,
        created: '',
        last_updated: ''
      });
    const fetchInfo = async () => {
        const response = await axios.get(apiBaseAddress + '/getSurvey?surveyId=' + id);
        setSurvey(response.data)
    } 

    const model = new Model(survey.json)
    useEffect(() => {
        fetchInfo();
    }, [])   
    model
        .onComplete
        .add((sender: Model) => {
            dispatch(post({postId: id as string, surveyResult: sender.data, surveyResultText: JSON.stringify(sender.data)}))
            // dispatch(download(id as string))
        });

        

    return (<>
        <Survey model={model}/>
    </>);
}

export default Run;
