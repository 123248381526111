import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiBaseAddress } from '../models/survey'

export const load = createAsyncThunk('results/load', async (id: string) => {
    const response = await axios.get(apiBaseAddress + '/results?postId=' + id, {
      withCredentials: true,
      xsrfHeaderName: "X-CSRFToken",
      xsrfCookieName : "csrftoken",
    })
    return response.data
})

export const post = createAsyncThunk('results/post', async (data: {postId: string, surveyResult: any, surveyResultText: string}) => {
  const response = await axios.post(apiBaseAddress + '/post', data, {
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName : "csrftoken",
  })
  return response.data
})

// Кастомный запрос на создание API для скачивания результатов
export const download = createAsyncThunk('results/download', async (id: string) => {
  const response = await axios.get(apiBaseAddress + '/download_result?postId=' + id, {
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName : "csrftoken",
  })
  return response.data
})

// Тестовый запрос
// export const download = createAsyncThunk('results/download', async (id: string) => {
//   const response = await axios.get(apiBaseAddress + '/download')
//   return response.data
// })